import * as React from 'react';
import {CarouselMobile} from './CarouselMobile/CarouselMobile';
import {CarouselDesktop} from './CarouselDesktop/CarouselDesktop';
import s from './Carousel.scss';
import {Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useRef, useState} from 'react';
import type {Swiper as SwiperClass} from 'swiper/types';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export interface CarouselAriaLabels {
  arrowLeft: string;
  arrowRight: string;
}

interface CarouselProps {
  shouldShowArrowsOnHover: boolean;
  ariaLabels: CarouselAriaLabels;
  children?: React.ReactChild[];
}

export const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const swiperRef = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const {isMobile} = useEnvironment();

  const getSwiper = () => {
    return (
      <Swiper
        className={s.swiperRoot}
        wrapperClass={s.swiperWrapper}
        role={'group'}
        modules={[Mousewheel]}
        threshold={2}
        loop={true}
        speed={600}
        simulateTouch={false}
        slidesPerView={1}
        watchSlidesProgress={true}
        onSwiper={setSwiper}
        ref={swiperRef}
        onActiveIndexChange={/* istanbul ignore next */ () => setActiveSlide(swiper?.realIndex)}>
        {children.map((image, i) => (
          <SwiperSlide key={i} className={s.swiperSlide} tabIndex={-1}>
            {image}
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const {shouldShowArrowsOnHover, ariaLabels, children} = props;

  return isMobile ? (
    <CarouselMobile activeSlide={activeSlide} slidesCount={swiper?.slides?.length || 2} slideTo={swiper?.slideTo}>
      {getSwiper()}
    </CarouselMobile>
  ) : (
    <CarouselDesktop
      ariaLabels={ariaLabels}
      shouldShowArrowsOnHover={shouldShowArrowsOnHover}
      slideNext={/* istanbul ignore next */ () => swiper?.slideNext()}
      slidePrev={/* istanbul ignore next */ () => swiper?.slidePrev()}>
      {getSwiper()}
    </CarouselDesktop>
  );
};
